import React from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Logo from "../components/logo.svg"
import { mergeClasses } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
  },
  logo: {
    width: "300px",
  },
  titleText: {
    textAlign: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "240px",
  },
}))

export default function Home(props) {
  const classes = styles()
  return (
    <Layout landing={true}>
      <SEO />
      <Grid container justify="center">
        <h1 className={classes.titleText}>
          Personal Finance Doesn't Have to Be Complicated
        </h1>
      </Grid>
      <div className={classes.center}>
        <img
          src={Logo}
          className={classes.logo}
          alt="Logo of a map with a dollar sign"
        />
      </div>
      <p>
        Are you ready to explore personal finance? This website was created to
        give a high level, foundational overview of what it takes to build
        wealth over time and be in control of your finances. We want to discuss
        the basics and give you the building blocks to tackle whatever happens
        with your finances on your own.
      </p>
      <p>
        This website is:
        <ul>
          <li>
            <strong>Opinionated</strong> - It is based on our view of the
            economy and risk tolerance.
          </li>
          <li>
            <strong>Focused on the United States</strong> - We are most familiar
            with the USA, because we live there.
          </li>
          <li>
            <strong>Not the Only Approach</strong> - There are many ways to
            handle your finances and invest money, this is just our preferred
            way.
          </li>
        </ul>
      </p>
      <p>
        We feel confident that understanding the basics of compound interest,
        investing, paying off debt, and taxes can give you the ability to
        succeed financially.
      </p>
      <Grid container justify="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/intro/definitions/"
        >
          Get Started!
        </Button>
      </Grid>
    </Layout>
  )
}
